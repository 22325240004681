<template>
  <div id="updateCalendar" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">ACTUALIZAR CALENDARIO</v-row>
      <v-container fluid class="addForms">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="4" class="ml-5 formularios">
            <!--EMPRESA-->
            <v-row>
              <label for="calendar">Empresa</label>
              <select
                name="empresa"
                id="empresa"
                v-model="dataCalendar.empresaId"
                disabled
              >
                <option disabled value="0" selected>Selecciona Empresa</option>
                <option
                  v-for="enterprise in enterprises"
                  :key="enterprise.id"
                  v-bind:value="enterprise.id"
                >
                  {{ enterprise.razonSocial }}
                </option>
              </select>
            </v-row>
            <!--CALENDARIO-->
            <v-row>
              <label for="calendar">Nombre</label>
              <input
                type="text"
                name="calendar"
                id="calendar"
                class="inputs"
                autocomplete="false"
                placeholder="Calendario..."
                v-model="dataCalendar.nombre"
                maxlength="20"
                @keypress="alfaNumerico($event)"
              />
            </v-row>
            <v-row class="mt-2">
              <!--Descripcion-->
              <label for="description">Descripcion</label>
              <textarea
                id="description"
                placeholder="Editor de texto..."
                v-model="dataCalendar.descripcion"
                @keypress="alfaNumerico($event)"
              ></textarea>
            </v-row>
            <v-row>
              <!--Lunes-->
              <label for="lunes">Lunes</label>
              <select
                name="lunes"
                id="lunes"
                v-model="dataCalendar.lunesCatalogoId"
              >
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Martes-->
              <label for="martes">Martes</label>
              <select
                name="martes"
                id="martes"
                v-model="dataCalendar.martesCatalogoId"
              >
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Miercoles-->
              <label for="miercoles">Miercoles</label>
              <select
                name="miercoles"
                id="miercoles"
                v-model="dataCalendar.miercolesCatalogoId"
              >
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Martes-->
              <label for="jueves">Jueves</label>
              <select
                name="jueves"
                id="jueves"
                v-model="dataCalendar.juevesCatalogoId"
              >
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Viernes-->
              <label for="viernes">Viernes</label>
              <select
                name="viernes"
                id="viernes"
                v-model="dataCalendar.viernesCatalogoId"
              >
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Sabado-->
              <label for="sabado">Sabado</label>
              <select
                name="sabado"
                id="martes"
                v-model="dataCalendar.sabadoCatalogoId"
              >
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <v-row>
              <!--Domingo-->
              <label for="domingo">Domingo</label>
              <select
                name="domingo"
                id="domingo"
                v-model="dataCalendar.domingoCatalogoId"
              >
                <option selected disabled value="0">Selecciona Tiempo</option>
                <option value="1">Tiempo completo</option>
                <option value="2">Descanso</option>
                <option value="3">Medio Tiempo</option>
              </select>
            </v-row>
            <!--Aplicar a-->
            <v-row class="mt-2">
              <label for="description">Aplica a</label>
              <select
                name="empresa"
                id="empresa"
                v-model="aplica"
                @change="condicion()"
              >
                <option disabled selected value="0">
                  Seleccione lugar de aplicación
                </option>
                <option value="1">Áreas</option>
                <option value="2">Sucursales</option>
              </select>
            </v-row>
            <!--areas-->
            <v-row class="mt-2" v-if="areaTrue">
              <label for="description">Cátalogo de áreas</label>
              <multiselect
                v-model="dataCalendar.calendarioAreas"
                trackBy="id"
                label="nombreArea"
                @input="areasAuxiliares"
                placeholder="Seleccione área(s)"
                tag-placeholder="Agregar área"
                selectLabel="Enter para agregar"
                deselectLabel="Enter para eliminar"
                selectedLabel="Seleccionado"
                :multiple="true"
                :options="areas"
                class="inputs"
                style="width: 170px !important"
              >
                <span slot="noResult"
                  >No se encontraron elementos con la búsqueda</span
                >
                <span slot="noOptions">No existen elementos</span>
              </multiselect>
            </v-row>
            <!--sucursales-->
            <v-row class="mt-2" v-if="branchTrue">
              <label for="description">Cátalogo de sucursales</label>
              <multiselect
                v-model="dataCalendar.calendarioSucursales"
                track-by="id"
                @input="sucursalAuxiliar"
                label="nombreSucursal"
                placeholder="Seleccione sucursal(es)"
                tag-placeholder="Agregar sucursal"
                selectLabel="Enter para agregar"
                deselectLabel="Enter para eliminar"
                selectedLabel="Seleccionado"
                :multiple="true"
                :options="branches"
                class="inputs"
                style="width: 170px !important"
              >
                <span slot="noResult"
                  >No se encontraron elementos con la búsqueda</span
                >
                <span slot="noOptions">No existen elementos</span>
              </multiselect>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="7" align-self="center">
            <!--Acciones-->
            <button class="botonAmarillo mt-5 ml-6" @click="validacion()">
              Guardar
            </button>
            <router-link to="/calendars" id="backCalendar">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Nofificaciones</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de modificar el Calendario?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Calendario</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      dataCalendar: [
        {
          enterprise: 0,
          calendar: "Produccion",
          lunes: 2,
          martes: 1,
          miercoles: 2,
          jueves: 2,
          viernes: 1,
          sabado: 3,
          domingo: 3,
          description: "Calendario de producccion",
        },
      ],
      enterprises: [],
      areaTrue: false,
      branchTrue: false,
      areas: [],
      areasAux: [],
      branches: [],
      sucursales: [],
      aplica: 0,
      sucursalesSeleccionadas: [],
      areasSeleccionadas: [],
    };
  },
  methods: {
    areasAuxiliares() {
      var auxAreas = [];
      this.areasAux = [];
      this.dataCalendar.calendarioAreas.forEach((area) =>
        this.areasAux.push(area.id)
      );
      this.areasAux.forEach((area) => {
        if (area != undefined) {
          auxAreas.push(area);
        }
      });
      //console.log("AuxInternoArea", auxAreas);
      this.areasAux = auxAreas;
      //areasAux es el que se pasa a la api
      //console.log("Aux que se envía", this.areasAux);
    },
    sucursalAuxiliar() {
      var auxSucursales = [];
      this.sucursales = [];
      this.dataCalendar.calendarioSucursales.forEach((suc) =>
        this.sucursales.push(suc.id)
      );
      this.sucursales.forEach((id) => {
        if (id != undefined) {
          auxSucursales.push(id);
        }
      });
      //console.log("AuxSucursalInterno", auxSucursales);
      this.sucursales = auxSucursales;
      //sucursales es el que se pasa a la api
      //console.log("array que se envia", this.sucursales);
    },
    guardar() {
      this.advice = false;
      /*console.log("llegue a modificar");
      console.log(this.areasAux);
      console.log(this.sucursales);*/
      this.show = true;
      axios
        .put(
          Server + "/calendarios/" + this.dataCalendar.id,
          {
            Id: this.dataCalendar.id,
            EmpresaId: this.dataCalendar.empresaId,
            Nombre: this.dataCalendar.nombre,
            Descripcion: this.dataCalendar.descripcion,
            LunesCatalogoId: parseInt(this.dataCalendar.lunesCatalogoId),
            MartesCatalogoId: parseInt(this.dataCalendar.martesCatalogoId),
            MiercolesCatalogoId: parseInt(
              this.dataCalendar.miercolesCatalogoId
            ),
            ViernesCatalogoId: parseInt(this.dataCalendar.viernesCatalogoId),
            JuevesCatalogoId: parseInt(this.dataCalendar.juevesCatalogoId),
            SabadoCatalogoId: parseInt(this.dataCalendar.sabadoCatalogoId),
            DomingoCatalogoId: parseInt(this.dataCalendar.domingoCatalogoId),
            Sucursales: this.sucursales,
            Areas: this.areasAux,
          },
          {
             headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          // console.log(response);
          localStorage.idCalendar = "";
          this.respuesta = "El calendario fue modificado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            localStorage.idCalendar = "";
            this.respuesta = "El calendario no fue modificado.";
            this.confirmation = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (
        this.dataCalendar.empresaId == " " ||
        this.dataCalendar.enterprise == 0
      ) {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (this.dataCalendar.nombre == " " || this.dataCalendar.calendar == "") {
        this.errors.push("Se debe especificar nombre del calendario.");
      }
      if (
        this.dataCalendar.descripcion == " " ||
        this.dataCalendar.description == ""
      ) {
        this.errors.push("Se debe especificar la descripcion del calendario.");
      }
      if (this.dataCalendar.lunesCatalogoId == 0) {
        this.errors.push("Se debe seleccionar el horario el día lunes.");
      }
      if (this.dataCalendar.martesCatalogoId == 0) {
        this.errors.push("Se debe seleccionar el horario el día martes.");
      }
      if (this.dataCalendar.miercolesCatalogoId == 0) {
        this.errors.push("Se debe seleccionar el horario el día miercoles.");
      }
      if (this.dataCalendar.juevesCatalogoId == 0) {
        this.errors.push("Se debe seleccionar el horario el día jueves.");
      }
      if (this.dataCalendar.viernesCatalogoId == 0) {
        this.errors.push("Se debe seleccionar el horario el día viernes.");
      }
      if (this.dataCalendar.sabadoCatalogoId == 0) {
        this.errors.push("Se debe seleccionar el horario el día sabado.");
      }
      if (this.dataCalendar.domingoCatalogoId == 0) {
        this.errors.push("Se debe seleccionar el horario el día domingo.");
      }
      if (this.aplica == 1 && this.dataCalendar.calendarioAreas.length == 0) {
        this.errors.push("Se debe especificar el(las) área(s).");
      }
      if (
        this.aplica == 2 &&
        this.dataCalendar.calendarioSucursales.length == 0
      ) {
        this.errors.push("Se debe especificar la(s) sucursal(es).");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    aux() {
      this.confirmation = false;
      localStorage.idCalendar = "";
      this.$router.push("/calendars");
    },
    cancelar() {
      localStorage.idCalendar = "";
      this.advice = false;
      this.$router.push("/calendars");
    },
    recuperar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/calendarios/" + localStorage.idCalendar, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          /*console.log("calendario");
          console.log(response);*/
          this.dataCalendar = response.data;
          if (this.dataCalendar.calendarioSucursales.length > 0) {
            this.dataCalendar.calendarioSucursales.forEach((element, index) => {
              this.sucursales.push(element.id);
            });
            this.aplica = 2;
            this.areaTrue = false;
            this.branchTrue = true;
            this.areasAux = [];
          } else if (this.dataCalendar.calendarioAreas.length > 0) {
            this.dataCalendar.calendarioAreas.forEach((element, index) => {
              this.areasAux.push(element.id);
            });
            this.aplica = 1;
            this.sucursales = [];
            this.branchTrue = false;
            this.areaTrue = true;
          }
          this.obtenerAreasSucursales();
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerAreasSucursales() {
      this.branches = [];
      this.show = true;
      axios
        .get(
          Server + "/sucursales/por-empresa/" + this.dataCalendar.empresaId,
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          response.data.forEach((element) => {
            if (
              element.calendarioId == null ||
              element.calendarioId == localStorage.idCalendar
            ) {
              this.branches.push(element);
            }
          });
          //console.log(this.branches);
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (
              value.empresaId == this.dataCalendar.empresaId &&
              (value.calendarioId == null ||
                value.calendarioId == localStorage.idCalendar)
            ) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
                nombreCorto: value.nombreAreaCorto,
                empresa: value.empresaId,
                nombreEmpresa: value.empresa,
                nivelJerarquico: value.nivelJerarquico,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    condicion() {
      if (this.aplica == "1") {
        this.dataCalendar.calendarioAreas = [];
        this.sucursales = [];
        this.branchTrue = false;
        this.areaTrue = true;
      }
      if (this.aplica == "2") {
        this.dataCalendar.calendarioSucursales = [];
        this.areasAux = [];
        this.areaTrue = false;
        this.branchTrue = true;
      }
    },
  },
  created() {
    this.recuperar();
  },
};
</script>
<style>
.v-application ol,
.v-application ul {
  padding-left: 0px !important;
}
.multiselect__option.multiselect__option--highlight {
  background: rgb(250, 181, 52) !important;
}
.multiselect__tag {
  background: rgba(253, 175, 29, 0.856) !important;
}
</style>